@import 'css/layout.scss';
@import 'css/utils.scss';
@import 'css/router.scss';

.my-drawer .ant-drawer-content-wrapper {
  max-width: 100%;
}

@media (max-width: 576px) {
  .my-drawer.ant-drawer-open {
    > .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.indiana-scroll-container {
  cursor: grab;
}
